import { useEffect, useState } from 'react';

const useMediaQuery = () => {
  const isClient = typeof window === 'object';

  const getQuery = () => {
    const query: MediaQueryType | undefined = isClient ? (mediaQueries.findIndex((q: MediaQueryType) => window.innerWidth <= q.value) !== -1 ?
      mediaQueries.find((q: MediaQueryType) => window.innerWidth <= q.value
      ) : { name: MediaQueryNames.DESKTOP, value: 0 })
      : { name: MediaQueryNames.DESKTOP, value: 0 };
    return query?.name;
  }


  const [windowQuery, setWindowQuery] = useState(getQuery);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    const handleResize = () => {
      setWindowQuery(getQuery());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowQuery;
};

export default useMediaQuery;


const mediaQueries: MediaQueryType[] = [
  { name: 'phone', value: 480 },
  { name: 'tablet', value: 840 },
  { name: 'desktop', value: 960 }
]

export enum MediaQueryNames {
  'PHONE' = 'phone',
  'TABLET' = 'tablet',
  'DESKTOP' = 'desktop'
}

type MediaQueryType = { name: string, value: number };