
import React, { Fragment, useContext } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Layout from '@components/layout';
import SEO from '@components/seo';
import OwnerIdDisplayer from '@components/owner-id-displayer';
import { DomainType } from '../models/Domain.type';
import TimelinesDisplayer from '@components/timelines-displayer';
import EdmusContext from '@components/application.provider';

export const query = graphql`
{
  prismic {
      allDomains {
        edges {
          node {
            _meta {
              lang
            }
            images {
              image
            }
            ownerId {
              keyId
              keyName
              keyValue
            }
            imageIntroduction
            ownerImage
            content1
            content2
            introduction
            largeImage1
            largeImage2
            name
            portraitCitation
            quote
            title
          }
        }
      }
    }
  }
`;

const Domain = (props: any) => {
  const { language } = useContext(EdmusContext);
  const doc: DomainType = props.data.prismic.allDomains.edges.find(
    (domain: DomainType) => domain.node._meta.lang.slice(0, 2) === language
  );

  if (!doc) return null;
  return (

    <Layout>
      <div className='domain'>
        {doc.node.imageIntroduction &&
          <img className='img-bar lazyload img-responsive' data-src={doc.node.imageIntroduction.url} alt={doc.node.imageIntroduction.alt} />
        }
        <SEO title={doc.node.title} />
        <div className='page-title space-top-20'>{doc.node.title}</div>
        <div className='container sub-container'>
          <div className='columns'>
            <div className='col introduction'>{RichText.render(doc.node.introduction)}</div>
          </div>
        </div>

        {doc.node.largeImage1 &&
          <div className='space-top-20 '>
            <img className='img-bar lazyload img-responsive' data-src={doc.node.largeImage1.url} alt={doc.node.largeImage1.alt} />
          </div>
        }

        <div className='container sub-container'>
          <div className='columns space-top-40'>
            <div className='col-5 col-md-12'>
              <div className='quote-portrait'>
                {doc.node.portraitCitation && <img className='p-centered img-responsive lazyload' data-src={doc.node.portraitCitation.url} alt={doc.node.portraitCitation.alt} />}
              </div>
            </div>
            <div className='col-7 col-md-12 quote-container'>
              <div className='quote'>
                <div>{RichText.render(doc.node.quote)}</div>
              </div>
            </div>
          </div>
          <div className='columns  space-top-40'>
            <div className='col content'>{RichText.render(doc.node.content1)}</div>
          </div>
          <div className='space-top-20 images-band  mb-2'>
            <div className='columns'>
              {doc.node.images && doc.node.images[0] && doc.node.images[1] &&
                <Fragment>
                  <img className='col-8  col-md-12 lazyload img-responsive img-fit-contain' data-src={doc.node.images[0].image.url} alt={doc.node.images[0].image.alt} />
                  <img className='col-4  col-md-12 lazyload img-responsive' data-src={doc.node.images[1].image.url} alt={doc.node.images[1].image.alt} />
                </Fragment>
              }
            </div>
          </div>
        </div>

        <div className='container  sub-container'>
          <div className='columns  space-top-40'>
            <div className='col rich-content title-middle'>{RichText.render(doc.node.content2)}</div>
          </div>
        </div>

        {doc.node.largeImage2 &&
          <div className='space-top-40 '>
            <img className='img-bar img-responsive lazyload' data-src={doc.node.largeImage2.url} alt={doc.node.largeImage2.alt} />
          </div>
        }
        <div className='space-top-40 mb-2'>
          <TimelinesDisplayer />
        </div>
        <div className='space-top-40 mb-2'>
          <OwnerIdDisplayer ownerId={doc.node.ownerId} ownerImage={doc.node.ownerImage} />
        </div>
      </div>
    </Layout >
  );
};

export default Domain;
