import React from 'react';
import { OwnerIdType, keyIds } from 'src/models/OwnerId.type';

const OwnerIdDisplayer = (props: { ownerId: OwnerIdType, ownerImage: { url: string, alt: string } }) => {

  if (!props.ownerId || !props.ownerImage) return null;
  return (
    <div className='owner-id-displayer space-top-30 space-bottom-30 container'>

      <div className='container  sub-container'>
        <h4 className='col text-center space-bottom-20'>{props.ownerId.find((c) => c.keyId === 'title')?.keyValue}</h4>
        <div className='columns space-top-30'>
          <div className='column col-4 col-sm-12 space-bottom-20 fix-column'>
            <div className='owner-portrait'>
              <div className='sticker'></div>
              <img className='ownerImage img-responsive' src={props.ownerImage.url} alt={props.ownerImage.alt} />
              <div className='linkedin'>
                <a href='https://www.linkedin.com/in/laurentddavid/' target='_blank'><i className='orange-linkedin' /></a>
              </div>
            </div>

          </div>
          <div className='col-8 col-sm-12'>
            <div className='columns manage-margin'>
              {props.ownerId.filter((c) => c.keyId !== 'title').map((c: { keyId: keyIds, keyName: string, keyValue: string }, key: number) =>
                <div key={key} className={c.keyId === 'path' ? 'column col-12  col-md-12 space-top-20' : 'column col-6 col-md-12'} >
                  {c.keyId === 'path' && <div className='decoration'></div>}
                  <p className='keyName'>{c.keyName}</p>
                  <p>{c.keyValue}</p>
                </div>)
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerIdDisplayer;
