import React, { useState, useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { PrismicImage } from 'src/models/Prismic.types';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Logo from './../static/img/logo.svg';
import useMediaQuery, { MediaQueryNames } from '@hooks/useMediaQuery';
import EdmusContext from './application.provider';

const TimelinesDisplayer = (props: any) => {
  const { prismic } = useStaticQuery(
    graphql`
      query {
        prismic {
          allTimelines(sortBy: rank_ASC) {
            edges {
              node {
                dates
                content
                image
                _meta {
                  lang
                }
              }
            }
          }
        }
      }
    `
  );

  const { language } = useContext(EdmusContext);
  const [timelines, setTimelines] = useState<TimelineType[]>();
  const query = useMediaQuery();
  const getTimeline = () => {
    return prismic.allTimelines.edges.filter(
      (timeline: TimelineType) =>
        timeline.node._meta.lang.slice(0, 2) === language
    );
  };

  useEffect(() => {
    const timelineS = getTimeline();
    setTimelines(timelineS);
  }, [language]);

  const getSlidesPerPages = () => {
    if (!query) return 1;
    switch (query) {
      case MediaQueryNames.DESKTOP:
        return 3;
      case MediaQueryNames.TABLET:
        return 2;
      case MediaQueryNames.PHONE:
      default:
        return 1;
    }
  };

  if (!timelines) return null;
  return (
    <div className='timelines-displayer space-top-30 space-bottom-30'>
      <div className='container '>
        <div className='columns'>
          <Carousel
            {...(query && query === MediaQueryNames.PHONE && 'centered')}
            slidesPerPage={getSlidesPerPages()}
            arrowLeft={<i className='icon  icon-arrow-left' />}
            arrowRight={<i className='icon  icon-arrow-right' />}
            arrowLeftDisabled={<i className='icon disabled' />}
            arrowRightDisabled={<i className='icon disabled' />}
            addArrowClickHandler
            infinite
          >
            {timelines &&
              timelines.map((timeline, key) => (
                <div key={key} className=''>
                  <div className='dates'>{timeline.node.dates}</div>

                  <div className='time-card'>
                    {timeline.node.image ? (
                      <img
                        className='img-responsive'
                        src={timeline.node.image.url}
                        alt={timeline.node.image.alt}
                      />
                    ) : (
                        <img
                          className='img-responsive'
                          src={Logo}
                          alt={'Château Edmus logo'}
                        />
                      )}
                    <div className='middle'>
                      <div className='text'>
                        {RichText.render(timeline.node.content)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default TimelinesDisplayer;

type TimelineType = {
  node: {
    dates: string;
    image: PrismicImage;
    title: string;
    content: any;
    _meta: {
      lang: string;
      uid: string;
    };
  };
};
